<template>
<Section>
    <div class="map-overlay">
      <!-- Выводит слева список всех маршрутов -->
      <!-- При клике на маршрут можно нарисовать его на карте -->
      <div class="routeFilter">
        <button style="display: flex; align-items: center"
                v-for="(item, index) in routes"
                :key="item.id"
                class="routeFilter__item"
                @click="findRoute(item.id, index)">
          <div
                  class="route-logo"
                  :style="{'background-color': routeColors[index]}">
            {{ item.num }}
          </div>
          <div style="margin-left: 15px">
            <p style="text-align: left; font-size: 0.7em">{{ item.fromst }}</p>
            <p style="text-align: left; font-size: 0.7em">{{ item.tost }}</p>
          </div>
        </button>
        <button class="routeFilter__item" @click="polyline.condition = false">
          <div class="route-logo" style="background-color: white">X
          </div>
        </button>
      </div>
      <!-- Карта -->
      <LMap style="height: 700px"
            :zoom="parseInt(zoom)" :center="center">
        <LTileLayer :url="url"></LTileLayer>
        <!-- l-feature group необходим для открытия popup при клике не на маркер-->
        <!-- Вызывается при нажатии на остановку которая перечислена в списке справа-->
        <l-feature-group ref="features">
          <l-popup > <span> Остановка "{{ stationName }}"</span></l-popup>
        </l-feature-group>
        <LCircleMarker
                ref="marker"
                @click="ShowCloseStationInfo(true, item), sched(item)"
                v-for="item in stations"
                :key="item.id"
                :lat-lng="item.center"
                :radius="stationStyle.radius"
                :color="stationStyle.color"
                :fillColor="stationStyle.fillColor"
                :fillOpacity="stationStyle.fillOpacity"
        >
          <LPopup>Остановка "{{ item.name }}"</LPopup>
        </LCircleMarker>
        <!-- Движущийся маркер для автобусов -->
        <!-- :icon обращается к методу который рисует кастомную иконку из html элементов -->
        <LMovingMarker
                @click="flashlightRoute(item.rid), showStationsOnRoute(item), routeInfo = true"
                v-for="item in vehicles"
                :key="item.id"
                :lat-lng="item.coords"
                :duration="6000"
                :icon="CustomDivIcon(item)">
          <LPopup>Автобус №{{ item.routeNumber }}</LPopup>
        </LMovingMarker>
        <!-- Рисует линию маршрута автобуса на карте, каждый маршрут своим цветом -->
        <LPolyline v-if="polyline.condition" :lat-lngs="gps"
                   :color="polyline.color"></LPolyline>
        <LControl position="bottomleft">
          <button @click="polyline.condition = false">
            Сбросить отрисовку маршрута
          </button>
        </LControl>
      </LMap>
      <!-- Отображает информацию по станции -->
      <div v-show="stationInfo" class="station-info">
        <div class="station-info__container">
          <h2 class="station-info__title">Остановка "{{ stationName }}"</h2>
          <button class="station-info__close-button"
                  @click="ShowCloseStationInfo(false)">X</button>
          <!-- Прогноз прибытия транспорта на остановку -->
          <div class="station-info__forecast">
            <ul>
              <li class="station-info__forecast-item"
                  v-for="(item) in forecast"
                  :key="item.id">
                <div class="station-info__forecast-bus-route-marker"
                     :style="{'background-color': colors[item.rnum]}">{{
                  item.rnum }}
                </div>
                <div
                   v-if="forecastTime < 1"
                   style="color: green"
                   class="station-info__forecast-time"
                >{{ forecastTime(item.arrt) }}</div>
                <div
                  v-else
                  class="station-info__forecast-time"
                >{{ forecastTime(item.arrt) }}</div>
              </li>
            </ul>
            <!-- Выводится расписание движения транспорта -->
            <div class="station-schedule__container">
            <h6 class="station-schedule__title">Расписание движения общественного транспорта на
              станции {{ stationName }}</h6>
              <!-- Переключашки чтобы можно было смотреть до конца дня и на весь день -->
              <!-- По умолчанию показывается до конца дня от текущего времени -->
              <button @click="currentSchedule = true">До конца дня</button>
              <button @click="currentSchedule = false">На весь день</button>
            <table v-for="item in stationSchedule" :key="item.id" >
              <tbody>
              <tr v-for="(route, name) in item.routes"
                  :key="route.key">
                <td
                  v-if="route.showSchedule"
                  >
                  <!-- Некоторые номера маршрутов приходят в формате 1_23 -->
                  <!-- Приходится их резать таким образом -->
                  <span class="station-schedule__route-number"> {{ name.split('_')[0] }}</span>
                  <span
                    v-for="(it, index) in route.everyday"
                    :key="index">
                    <!-- Скидываем в метод time текущее время -->
                    <span v-if="currentSchedule">  {{ time(it) }}  </span>
                    <span v-if="!currentSchedule">  {{ it }}  </span>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>
      </div>
    </div>
      <!-- Выводит список станций на каждом конкретном маршруте! -->
      <div
        class="stations-on-route"
        v-if="stationsOnRouteList">
        <button
                class="stations-on-route__close-btn"
                @click="stationsOnRouteList = !stationsOnRouteList">X</button>
        <h2 class="stations-on-route__route-number">Маршрут № {{
          stationsOnRouteListRouteNumber }}</h2>
        <ol class="stations-on-route-list">
          <li
                  @click="center = item.coords, ShowCloseStationInfo(true, item), sched(item),
                    openStationPopUp(center, item)"
                  class="stations-on-route-list__item"
                  v-for="(item, index) in stationsOnRoute"
                  :key="index"> {{ index + 1 }} - {{ item.name }}</li>
        </ol>
      </div>
      <!-- Выводит список остановок с расписанием движения -->
      <div v-show="routeInfo" class="station-info">
        <div class="station-info__container">
          <h2 class="station-info__title">Маршрут № {{ stationsOnRouteListRouteNumber }}</h2>
          <button class="station-info__close-button"
                  @click="routeInfo = false">X</button>
          <div class="station-info__forecast station-info__forecast_route-info">
            <!-- Выводится расписание движения транспорта -->
            <div class="station-schedule__container">
              <!-- Переключашки чтобы можно было смотреть до конца дня и на весь день -->
              <!-- По умолчанию показывается до конца дня от текущего времени -->
              <button @click="currentSchedule = true">До конца дня</button>
              <button @click="currentSchedule = false">На весь день</button>
              <table>
                <tbody>
                <tr v-for="item in stationsOnRoute" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                <span
                        v-for="(it, index) in item.routes[stationsOnRouteListRouteNumber]"
                        :key="index">
                  <span
                          v-for="(stopTime, index) in it"
                          :key="index">
                    <span v-if="stopTime !== '1'">
                      <span v-if="currentSchedule">  {{ time(stopTime) }}  </span>
                      <span v-if="!currentSchedule">  {{ stopTime }}  </span>
                    </span>
                  </span>
                </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import L from 'leaflet';
import LMovingMarker from 'vue2-leaflet-movingmarker';
import {
  LMap, LTileLayer, LPolyline, LCircleMarker, LPopup, LControl, LFeatureGroup,
} from 'vue2-leaflet';

export default {
  name: 'TransportMap',
  beforeCreate() {
    this.$store.dispatch('REQUEST_ROUTES', process.env.VUE_APP_DEFAULTH_CITY_SLUG);
    this.$store.dispatch('REQUEST_STATIONS', process.env.VUE_APP_DEFAULTH_CITY_SLUG);
    this.$store.dispatch('REQUEST_GPS_BY_ROUTE', process.env.VUE_APP_DEFAULTH_CITY_SLUG);
  },
  mounted() {
    this.$store.dispatch('REQUEST_SCHEDULE');
    // Интервал запрашивает координаты автобусов чтобы компонент LMovingMarker мог двигаться
    setInterval(() => this.$store.dispatch('REQUEST_VEHICLES', process.env.VUE_APP_DEFAULTH_CITY_SLUG), 2000);
    // Запрашиваем расписание всех автобусов
  },
  computed: {
    routes() {
      const routesJson = this.$store.getters.GET_ROUTES;

      /**
       * Remove duplicates from an array of objects in javascript
       * @param arr - Array of objects
       * @param prop - Property of each object to compare
       * @returns {Array}
       */
      function removeDuplicates(arr, prop) {
        const obj = {};
        return Object.keys(arr.reduce((prev, next) => {
          if (!obj[next[prop]]) obj[next[prop]] = next;
          return obj;
        }, obj))
          .map((i) => obj[i]);
      }

      const uniqueRoutes = removeDuplicates(routesJson, 'num');
      return uniqueRoutes;
    },
    // Здесь и далее координаты делятся на 1000000 чтобы получить число с плавающей точкой
    // API присылает их в виде целых чисел - lat: 44144405 lng: 43016029
    gps() {
      return this.$store.getters.GET_GPS.map((elem) => ({
        lat: elem.lat / 1000000,
        lng: elem.lng / 1000000,
      }));
    },
    stations() {
      return this.$store.getters.GET_STATIONS.map((elem) => ({
        id: elem.id,
        center: [elem.lat / 1000000, elem.lng / 1000000],
        name: elem.name,
      }));
    },
    vehicles() {
      return this.$store.getters.GET_VEHICLES.map((elem) => ({
        id: elem.id,
        routeDirection: elem.dir,
        speed: elem.speed,
        routeNumber: elem.rnum,
        coords: [elem.lat / 1000000, elem.lon / 1000000],
        name: elem.name,
        rid: elem.rid,
      }));
    },
    forecast() {
      return this.$store.getters.GET_STATION_FORECAST.map((elem) => ({
        id: elem.index,
        arrt: elem.arrt,
        rnum: elem.rnum,
      }));
    },
    stationsOnRoute() {
      const data = this.$store.getters.GET_STATIONS_ON_ROUTE;
      const filteredData = data.map((elem) => {
        // eslint-disable-next-line no-unused-vars
        const number = this.stationsOnRouteListRouteNumber;
        const objectToPushFiltered = {};
        // eslint-disable-next-line no-restricted-syntax,no-unused-vars
        for (const [key, value] of Object.entries(elem.routes)) {
          const fixNumber = key.split('_')[0];
          // eslint-disable-next-line eqeqeq
          if (fixNumber == number) {
            objectToPushFiltered[fixNumber] = value;
          }
        }
        return {
          id: elem.id,
          name: elem.name,
          coords: [elem.lat / 1000000, elem.lng / 1000000],
          routes: objectToPushFiltered,
        };
      });
      return filteredData;
    },
    schedule() {
      return this.$store.getters.GET_SCHEDULE;
    },
  },
  methods: {
    // Открывает popUp по клику в списке остановок на маршруте
    openStationPopUp(latLng) {
      this.$refs.features.mapObject.openPopup(latLng);
    },
    // Сравнивает время и выводит только те которые больше текущего
    // чтобы отобразить расписание до конца дня
    // eslint-disable-next-line consistent-return
    time(item) {
      const t = new Date().toLocaleTimeString().slice(0, -3);
      if (t < item) {
        return item;
      }
    },
    // Фильтрует расписание всех остановок чтобы выводить по конкретной
    sched(item) {
      const ident = item.id;
      // eslint-disable-next-line eqeqeq
      const res = this.schedule.filter((e) => e.id == ident);
      this.stationSchedule = res;
      return res;
    },
    // Выводит прогноз прибытия автобусов в минутах
    forecastTime(arg) {
      let time = Math.ceil(arg / 60);
      if (time < 1) {
        time = 'прибывает';
      } else if (time >= 1) {
        time = `через ${time} мин`;
      }
      return time;
    },
    // Показывает блок информации по станции, делает запрос за прогнозом прибытия автобуса
    // Записывает в data название остановки чтобы его динамично переписывать
    // eslint-disable-next-line consistent-return
    ShowCloseStationInfo(arg, item) {
      if (arg === false) {
        this.stationInfo = arg;
        // this.stationName = null;
      } else if (arg === true) {
        this.stationInfo = arg;
        this.stationName = item.name;
        this.$store.dispatch('REQUEST_STATION_FORECAST', item.id);
      }
    },
    // Запрашивает координаты узлов (их еще называют ломаные) чтобы нарисовать на карте маршрут
    findRoute(item, index) {
      this.polyline.condition = false;
      this.polyline.color = this.routeColors[index];
      this.$store.dispatch('REQUEST_GPS_BY_ROUTE', item);
      this.polyline.condition = true;
    },
    // Рисует на карте маршрут каждый маршрут своим цветом
    flashlightRoute(item) {
      this.polyline.condition = false;
      if (item === 15) {
        this.polyline.color = '#e99b10';
      } else if (item === 16) {
        this.polyline.color = '#b94a4a';
      } else if (item === 17 || item === 18) {
        this.polyline.color = '#42771e';
      } else if (item === 19 || item === 20) {
        this.polyline.color = '#c1bf38';
      } else if (item === 21) {
        this.polyline.color = '#da16be';
      } else if (item === 22) {
        this.polyline.color = '#083e81';
      } else if (item === 23 || item === 24) {
        this.polyline.color = '#174958';
      } else if (item === 25 || item === 26) {
        this.polyline.color = '#b01dc0';
      } else if (item === 27) {
        this.polyline.color = '#85c01d';
      } else if (item === 28) {
        this.polyline.color = '#369896';
      } else if (item === 29 || item === 30) {
        this.polyline.color = '#884c25';
      } else {
        this.polyline.color = process.env.VUE_APP_DEFAULTH_RED;
      }
      this.$store.dispatch('REQUEST_GPS_BY_ROUTE', item);
      this.polyline.condition = true;
    },
    // Рисует кастомную иконку для LMovingMarker при помощи HTML элементов
    // Чтобы автобусы на карте имели каждый свой номер а не статичную картинку
    CustomDivIcon(item) {
      return L.divIcon({
        className: 'bus-item-container',
        html:
          `<div style="width: 30px; height: 30px; border: 2px solid #ffffff; border-radius: 50%; margin-left: -15px; margin-top: -15px; background-color: #4d4185; color: #FFFFFF; text-align: center; font-weight: bold; font-size: 16px; line-height: 30px">${item.routeNumber}</div>`,
        popupAnchor: [-5, -15],
      });
    },
    // Запрашивает список остановок на маршруте!
    showStationsOnRoute(item) {
      this.stationsOnRouteList = true;
      this.$store.dispatch('REQUEST_STATIONS_ON_ROUTE', item.rid);
      this.stationsOnRouteListRouteNumber = item.routeNumber;
    },
  },
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LCircleMarker,
    LPopup,
    LMovingMarker,
    LControl,
    LFeatureGroup,
  },
  data() {
    return {
      // Управляет отображением списка остановок с расписанием движения
      routeInfo: false,
      // Управляет отображением текущего расписания до конца дня
      currentSchedule: true,
      // Расписание на конкретной станции на весь день
      stationSchedule: null,
      // Цвета маршрутов в фильтре маршрутов слева
      colors: {
        1: '#e99b10',
        2: '#B94A4A',
        3: '#42771E',
        4: '#C1BF38',
        5: '#083E81',
        6: '#174958',
        7: '#b01dc0',
        9: '#85c01d',
        10: '#369896',
        '4а': '#da16be',
        '6а': '#884c25',
      },
      // Управляет отображением списка станций на маршруте
      stationsOnRouteList: false,
      // Здесь хранится номер маршрута в списке станций справа
      stationsOnRouteListRouteNumber: null,
      // Управляет отображение информации по конкретной станции
      stationInfo: false,
      // Здесь хранится название остановки
      stationName: null,
      // Параметры карты - начало
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: process.env.VUE_APP_DEFAULTH_MAP_ZOOM,
      center: [process.env.VUE_APP_DEFAULTH_LATITUDE, process.env.VUE_APP_DEFAULTH_LONGITUDE],
      polyline: {
        condition: false,
        color: process.env.VUE_APP_DEFAULTH_RED,
      },
      stationStyle: {
        fillColor: '#FFFFFF',
        fillOpacity: 0.9,
        radius: 6,
        color: process.env.VUE_APP_DEFAULTH_GREEN,
      },
      // - конец параметров карты
      // Цвета маршрута
      routeColors: ['#e99b10', '#B94A4A', '#42771E', '#C1BF38', '#083E81', '#174958', '#b01dc0',
        '#85c01d', '#369896', '#da16be', '#884c25', '#a04dcb'],
    };
  },
};
</script>
